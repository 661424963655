import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [3,2];

export const dictionary = {
		"/": [4],
		"/order/status/[order_id]/expired": [~9,[3]],
		"/order/status/[order_id]/pending": [~10,[3]],
		"/order/status/[order_id]/success": [~11,[3]],
		"/order/status/[order_id]/transfer-verification": [~12,[3]],
		"/order/[uuid]/[code]": [~5,[2]],
		"/order/[uuid]/[code]/expired": [6,[2]],
		"/order/[uuid]/[code]/register": [~7,[2]],
		"/order/[uuid]/[code]/register/quota-exceeded": [~8,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';